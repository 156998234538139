<div class="tc-table-page-header" tc-sticky-header fxLayoutAlign="space-between center">
    <div fxFlex>
        <div class="mat-headline-6">TradeCafé · Client Shipping Log</div>
    </div>
</div>

<div class="tc-table-page-filters wht-bg-container">
    <div style="width: 322px">
    <tc-select-search placeholder="Buyer"
                      [group]="filtersForm" ctrlName="buyer"
                      [items]="buyers$ | async"
                      bindValue="id"
                      bindLabel="name"
                      clearable="true"
    ></tc-select-search>
    </div>
</div>

<div *ngIf="filtersForm.controls.buyer.value && filtersForm.controls.buyer.value[0]" class="wht-bg-container">
    <h4>Client Shipping Log Preview</h4>
    <hr>
    <tc-deals-list
            [dataSource]="dataSource"
            [filtersForm]="filtersForm"
            [totals$]="totals$"
            [columnNames]="columnNames"
            [displayColumns]="displayColumns"
            [tableIdentity]="tableIdentity"
            [isEditBlocked]="true"
            [isScrollable]="true"
    ></tc-deals-list>
</div>





