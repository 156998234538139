import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { BUYER, DealRow, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { identity, isEqual } from 'lodash-es'
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'
import { loadAccounts, selectBuyersOptions } from 'src/app/store/accounts'
import { loadDealViews } from 'src/app/store/deal-view.actions'
import { selectDealsTable, selectDealsTotals } from 'src/app/store/deal-view.reducer'
import { DEFAULT_DEALS_FILTERS } from 'src/components/deals-list/deals-list.filters'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { FiltersService } from 'src/services/table-utils'
import { ApiTableDataSource } from 'src/services/table-utils/data-sources/api-table-data-source'
import { replayForm } from 'src/shared/utils/replay-form'
import { selectOptions } from 'src/shared/utils/select-options'
import { TableViewApiService } from '../../../../api/table-view'
import { columnNames, DEFAULT_COLUMNS } from '../../../../components/deals-list'
import { columnsMap } from '../../logistics/client-shipping-log/client-shipping-log-headers'

const mapColumns = (data) => data[0]?.columns.filter((i) => columnsMap[BUYER][i]).map((i) => columnsMap[BUYER][i] || i) || []

@Component({
  selector: 'tc-client-shipping-log',
  templateUrl: './client-shipping-log.component.html',
  styleUrls: ['./client-shipping-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientShippingLogComponent extends OnDestroyMixin implements OnInit {

  constructor(
      private elastic: DealElasticSearchService,
      private Filters: FiltersService,
      private store: Store,
      private TableViewApi: TableViewApiService,
      private sanitizer: DomSanitizer) { super() }

  readonly tableIdentity = TableKey.ClientShippingLogList

  styles = this.sanitizer.bypassSecurityTrustHtml(`<style>body::-webkit-scrollbar { display: none; }</style>`)

  columnNames = columnNames(this.tableIdentity)
  displayColumns = []
  availableColumns = DEFAULT_COLUMNS[this.tableIdentity]
  totals$ = this.store.select(selectDealsTotals(this.tableIdentity))

  protected readonly filtersForm = this.Filters.buildForm(this, {
    key: this.tableIdentity,
    cacheFilters: false,
    defaultValues: {
      ...DEFAULT_DEALS_FILTERS,
      columns: this.availableColumns,
    },
  })

  protected dataSource: ApiTableDataSource<DeepReadonly<DealRow>, any>

  protected buyers$ = selectOptions(this.store, selectBuyersOptions, this.filtersForm.controls.buyer)

  private filtersForm$ = replayForm(this.filtersForm)
  private deals$ = this.store.select(selectDealsTable(this.tableIdentity))


  ngOnInit(): void {
    this.store.dispatch(loadAccounts({}))
    const trigger$ = new Subject()

    // setup data source
    this.dataSource = new ApiTableDataSource<DeepReadonly<DealRow>, any>(
        this.deals$.pipe(filter(identity)),
        this.filtersForm,
        trigger$)


    // listen for fetch commands - fetch data
    this.dataSource.fetch$.pipe(untilComponentDestroyed(this), distinctUntilChanged(isEqual)).subscribe(({ filters, page }) => {
      if (!filters.buyer || !filters?.buyer[0]) return

      this.TableViewApi.list(filters.buyer, {user_id: filters.buyer}).then(({data}) => {
        this.displayColumns = mapColumns(data)
        this.store.dispatch(loadDealViews({
          tableKey: this.tableIdentity,
          page,
          filters: {...filters, columns: this.displayColumns },
          calculate: false,
        }))
      })
    })

    replayForm(this.filtersForm).pipe(untilComponentDestroyed(this)).subscribe(() => trigger$.next())
  }

}

