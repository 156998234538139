import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ClientShippingLogComponent } from './client-shipping-log.component'


const routes: Routes = [{ path: '', component: ClientShippingLogComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientShippingLogRoutingModule { }
