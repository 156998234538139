import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ModalModule } from 'src/shared/modal'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { DealsListModule } from '../../../../components/deals-list'
import { SelectSearchModule } from '../../../../components/select-search/select-search.module'
import { PipesModule } from '../../../../shared/pipes/pipes.module'
import { ClientShippingLogRoutingModule } from './client-shipping-log-routing.module'
import { ClientShippingLogComponent } from './client-shipping-log.component'

@NgModule({
  declarations: [ClientShippingLogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FlexLayoutModule,
    ModalModule,
    ToasterModule,
    ClientShippingLogRoutingModule,
    SelectSearchModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    DealsListModule,
  ],
})
export class ClientShippingLogModule { }
